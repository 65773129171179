<template>
  <div class="row">
    <div class="col-3">
      <aside>
        <div class="alert alert-secondary">
          <p class="mb-0 text-center journal-info">
            <span v-text="stock.journal.volume"></span> 卷
            <span v-text="stock.journal.period"></span> 期
          </p>
          <hr />
          <p class="mb-0 text-center journal-info">
            <strong class="text-muted" v-text="stock.journal.publishDateString"></strong>
          </p>
        </div>
        <img
          :src="stock.journal.pickFile.fileUrl"
          class="img-fluid rounded-start"
          :alt="stock.journal.title"
        />
      </aside>
    </div>
    <div class="col-9">
      <div class="content">
        <p class="mb-0">頁碼: {{ stock.pageNumber }}</p>
        <h1>{{ stock.subject }}<small class="text-muted" v-text="stock.subjectEng"></small></h1>
        <p class="text-end mt-2">
          <button type="button" class="btn btn-secondary btn-sm" @click="getPaper()">
            全文下載 ({{ stock.downloadCount }})
          </button>
        </p>
        <dl class="row">
          <dt class="col-sm-3 text-end">作者</dt>
          <dd class="col-sm-9" v-text="stock.author"></dd>
          <dt class="col-sm-3 text-end">作者(英)</dt>
          <dd class="col-sm-9" v-text="stock.authorEng"></dd>
          <dt class="col-sm-3 text-end">關鍵詞</dt>
          <dd class="col-sm-9" v-text="stock.keyword"></dd>
          <dt class="col-sm-3 text-end">關鍵詞(英)</dt>
          <dd class="col-sm-9" v-text="stock.keywordEng"></dd>
          <dt class="col-sm-3 text-end">摘要</dt>
          <dd class="col-sm-9" v-text="stock.summary"></dd>
          <dt class="col-sm-3 text-end">摘要(英)</dt>
          <dd class="col-sm-9" v-text="stock.summaryEng"></dd>
        </dl>
        <p class="text-center mt-4">
          <router-link
            class="btn btn-outline-secondary"
            :to="'/archives/journal/' + stock.journalId"
            title="返回"
            >返回</router-link
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { PublicAPI } from '@/api/index'
import AxiosErrMsg from '@/helper/AxiosErr.js'
import stateMerge from 'vue-object-merge'

export default {
  data() {
    return {
      stock: {
        journalId: '',
        journalCategoryId: '',
        subject: '',
        subjectEng: '',
        author: '',
        authorEng: '',
        keyword: '',
        keywordEng: '',
        summary: '',
        summaryEng: '',
        pageNumber: '',
        downloadCount: '',
        pickFile: {
          fileId: 0,
          functionCode: '',
          fileUrl: '',
          fileSource: ''
        },
        journal: {
          id: '',
          volume: '',
          period: '',
          title: '',
          publishDateString: '',
          pickFile: {
            fileId: 0,
            functionCode: '',
            fileUrl: '',
            fileSource: ''
          }
        }
      }
    }
  },
  mounted() {
    this.contentList()
  },
  methods: {
    async contentList() {
      let loader = this.$loading.show()
      await PublicAPI.Paper(this.$route.params.code)
        .then(response => {
          stateMerge(this.stock, response.data)
        })
        .catch(err => {
          AxiosErrMsg(err, this.$swal)
        })
      loader.hide()
    },
    async getPaper() {
      await PublicAPI.PaperFile(this.$route.params.code)
        .then(response => {
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: 'application/pdf' })
          )
          const link = document.createElement('a')
          link.setAttribute('download', this.stock.subject + '.pdf')
          link.href = url
          link.target = '_blank'
          link.click()

          this.stock.downloadCount++
        })
        .catch(err => {
          AxiosErrMsg(err, this.$swal)
        })
    }
  }
}
</script>

<style>
.journal-info span {
  font-weight: bolder;
  font-size: 3rem;
  color: #3645c7;
}
.journal-info strong {
  display: block;
}
h1 small {
  display: block;
  font-size: 1.5rem;
}
dl.row {
  padding: 1.5rem 0.5rem 0;
}
</style>
